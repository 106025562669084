<script lang="tsx">
import { defineComponentBaseModal } from '@core/app/components/base/BaseModal.vue'

type Sizes = 'lg' | 'md' | 'sm'

export default defineComponentBaseModal<Sizes>()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseModal" as *;

@include modal {
    border-radius: $sim-border-radius-sm;
    background-color: $sim-c-white;

    @include set-padding(2rem, 2rem, 2rem, 2rem);
}

@include modal__header {
    background-color: rgba($sim-c-white, 0.7);
    backdrop-filter: blur(10px);
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
}

@include modal-transition--active {
    transition: transform $sim-trans-time-normal $sim-timing,
    opacity $sim-trans-time-short $sim-timing;
}

@include modal-transition--from-to {
    transform: scale(0.8);
    opacity: 0;
}

@include modal--size('lg') {
    @include modal {
        width: min(100%, 56rem);
    }
}

@include modal--size('md') {
    @include modal {
        width: min(100%, 36rem);
    }
}

@include modal--size('sm') {
    @include modal {
        width: min(100%, 24rem);
    }
}

</style>
