<script lang="tsx">
import type { SlotsType, Ref } from 'vue'
import { Teleport, Transition } from 'vue'
import type { ComponentOverrideOptions } from '@core-types/components'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { type BaseModalProvide, SymbolBaseModal } from '@core/app/composables/components'
import { BaseFullscreenBackground } from '#components'

export type BaseModalProps<Sizes extends string> = {
    modelValue?: boolean
    ariaLabel?: string
    ariaLabelledby?: string
    ariaDescribedby?: string
    alert?: boolean

    noPadding?: boolean
    size?: Sizes
}

type BaseModalSlots<T> = {
    default: { close: () => void }
    header: { close: () => void }
}

type ComponentOptions = {}

export function defineComponentBaseModal<Sizes extends string = 'md'>(options?: ComponentOverrideOptions<ComponentOptions, BaseModalProps<Sizes>, BaseModalSlots<Sizes>>) {
    return defineComponent(
        (props: BaseModalProps<Sizes>, ctx) => {

            const parentScope = getScopeIdAttr()

            const isOpen = computed<boolean>({
                get() {
                    return props.modelValue ?? false
                },
                set(val: boolean) {
                    ctx.emit('update:modelValue', val)
                },
            })

            const showWrapper = ref<boolean>(isOpen.value)

            // the content of the modal
            const modalContent: Ref<HTMLElement | null> = ref(null)

            // the element that was focused when the modal was opened (the one that opened the modal)
            let prevActiveElement: HTMLElement | null = null

            // focus trap library initialization
            const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(modalContent, {
                escapeDeactivates: false,
                allowOutsideClick: true,
                setReturnFocus: () => {
                    // return the element to set the focus to, or `false` (not to focus anything)
                    return prevActiveElement ?? false
                },
                fallbackFocus: () => document.body,
            })

            // page properties store for global body scroll lock
            const { tryToUnlockScroll } = usePopupsStore()

            function onEnter() {
                showWrapper.value = true
            }

            /**
             * A callback function that is called after the sidebar open animation is finished.
             * Activates the focus trap. (because there can be problems with focusing elements that are being animated)
             */
            function afterEnter() {
                try {
                    activateFocusTrap()  // activate the focus trap
                } catch (e) {
                    /*
          When there are no focusable elements inside the modal, the focus trap throws an error.
         */
                }
            }

            /**
             * A callback function that is called after the close animation is finished.
             * Unlocks the scroll lock of the body. (so that the scroll bar only appears after the close animation is finished,
             * to prevent a visual shift)
             */
            function afterLeave() {
                tryToUnlockScroll()
                showWrapper.value = false
            }

            /**
             * A function used to save the currently open modal to the store & clean it up after it's closed.
             * @private
             */
            function closeModal() {
                isOpen.value = false
            }


            // WATCHERS & LIFE CYCLE HOOKS ------------------------------------------------

            useManagePopupOpening(isOpen, {
                closeCallback: closeModal,
                autoUnlockScroll: false,
            }, {
                onOpen: () => {
                    // save the previously focused element, before the modal was opened (the one that opened the modal)
                    prevActiveElement = document.activeElement as HTMLElement
                    // emit opened event
                    ctx.emit('opened')
                },
                onClose: () => {
                    // deactivate the focus trap when the modal is closed
                    deactivateFocusTrap()
                    // emit closed event
                    ctx.emit('closed')
                },
            })

            ctx.expose({
                close: closeModal,
            })

            provide<BaseModalProvide>(SymbolBaseModal, {
                close: closeModal,
            })

            return () => (
                <Teleport to="#teleports">
                    <div
                        style={showWrapper.value ? undefined : 'display: none'}
                        class={['sim-modal', {
                            'sim-modal--open': isOpen.value,
                            ['sim-modal--no-padding']: props.noPadding,
                            [`sim-modal--s-${props.size}`]: props.size,
                        }]}
                    >
                        <BaseFullscreenBackground {...{
                            'modelValue': isOpen.value,
                            'onUpdate:modelValue': (val: boolean) => isOpen.value = val,
                        }} />

                        <Transition
                            appear
                            onEnter={onEnter}
                            onAfterEnter={afterEnter}
                            onAfterLeave={afterLeave}
                        >
                            {
                                // MAIN CONTENT
                                // `v-if` is important, `v-show` cannot be used because it wouldn't
                                // re-mount forms, for example, when the modal is opened again
                                // and therefore, the form would not be reset.

                                isOpen.value && <div {...{
                                    ...ctx.attrs,
                                    ...parentScope,
                                    'ref': modalContent,
                                    'class': ['sim-modal__container', ctx.attrs.class],
                                    'aria-label': props.ariaLabel,
                                    'aria-labelledby': props.ariaLabelledby,
                                    'aria-describedby': props.ariaDescribedby,
                                    'aria-modal': isOpen.value ? true : undefined,
                                    'role': props.alert ? 'alertdialog' : 'dialog',
                                }}
                                >
                                    <div class="sim-modal__content">
                                        {(ctx.slots.header !== undefined || options?.slots?.header) &&
                                            <div class="sim-modal__header">
                                                {renderSlot(ctx.slots.header, options?.slots?.header, {
                                                    close: closeModal,
                                                })}
                                            </div>
                                        }

                                        {renderSlot(ctx.slots.default, options?.slots?.default, {
                                            close: closeModal,
                                        })}
                                    </div>
                                </div>
                            }
                        </Transition>
                    </div>
                </Teleport>
            )
        },
        {
            inheritAttrs: false,
            props: {
                modelValue: {
                    type: Boolean as PropType<BaseModalProps<Sizes>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<BaseModalProps<Sizes>['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaLabelledby: {
                    type: String as PropType<BaseModalProps<Sizes>['ariaLabelledby']>,
                    default: options?.props?.ariaLabelledby?.default,
                    required: options?.props?.ariaLabelledby?.required ?? false,
                },
                ariaDescribedby: {
                    type: String as PropType<BaseModalProps<Sizes>['ariaDescribedby']>,
                    default: options?.props?.ariaDescribedby?.default,
                    required: options?.props?.ariaDescribedby?.required ?? false,
                },
                alert: {
                    type: Boolean as PropType<BaseModalProps<Sizes>['alert']>,
                    default: options?.props?.alert?.default,
                    required: options?.props?.alert?.required ?? false,
                },

                noPadding: {
                    type: Boolean as PropType<BaseModalProps<Sizes>['noPadding']>,
                    default: options?.props?.noPadding?.default,
                    required: options?.props?.noPadding?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<BaseModalProps<Sizes>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseModalSlots<Sizes>>,
            emits: {
                'update:modelValue': (val: boolean) => true,
                'opened': () => true,
                'closed': () => true,
            },
        }
    )
}

export default defineComponentBaseModal()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseModal.scss" as *;

@include modal {
    border-radius: 0.5rem;
    background-color: white;

    @include set-padding(2rem, 2rem, 2rem, 2rem);
}

@include modal__header {
    background-color: rgba(#fff, 0.7);
    backdrop-filter: blur(10px);
    margin-bottom: 1rem;
}

@include modal-transition--active {
    transition: transform 200ms cubic-bezier(.4,0,.2,1),
    opacity 120ms cubic-bezier(.4,0,.2,1);
}

@include modal-transition--from-to {
    transform: scale(0.8);
    opacity: 0;
}

@include modal--size('md') {
    @include modal {
        width: min(100%, 36rem);
    }
}


</style>
